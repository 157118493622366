body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fc-type-time-container {
    position: absolute;
    top: -30px;
    border: none !important;
    background: none !important;
}

.fc-type-hours-content {

}

.fc-type-default-container {
    padding: 2px;
    overflow: hidden;
    background: #2065D1 !important;
    color: #ffffff;
    cursor: default;
}

.fc-type-default-content {
    overflow: hidden;
    text-overflow: ellipsis;
}

.fc-popover-body .fc-type-time-container {
    display: none;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    pointer-events: none;
    animation: spinner 1.5s linear infinite;
}

.loadingscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255);
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 100000000;
}

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    /*//background-color: rgba(136, 136, 136, 0.4);*/
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 100000000;
}

.containerImg {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 200px;
    border-radius: 50%; /* Закругление углов для создания круглой формы */
    /*overflow: hidden; !* Ограничение содержимого круглой областью *!*/
}

.image {
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    height: 200px;
    opacity: 0;
    transition: .3s ease;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 60%;
}

.containerImg:hover .overlay {
    opacity: 1;
}

.icon {
    color: white;
    font-size: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}